import InputError from '@/components/atoms/Inputs/InputError'
import cn from 'classnames'
import {Field, FormikProps} from 'formik'
import {ChangeEvent, useState} from 'react'
import Icon from '../../Icon'
import styles from './Select.module.scss'

interface Option {
  text: string
  value: string
}

interface SelectProps {
  className?: string
  description?: string
  id: string
  isRequired?: boolean
  label?: string
  options?: Option[]
  placeholder?: string
  defaultValue?: string
  errorMessage?: string
  konto?: boolean
  setFieldValue: FormikProps<any>['setFieldValue']
  extensionFieldId?: string
}

export default function Select({
  className,
  description,
  id,
  isRequired = false,
  label,
  options,
  errorMessage,
  konto,
  setFieldValue,
  defaultValue,
  extensionFieldId = ''
}: SelectProps) {
  const [showError, setShowError] = useState<boolean>(false)
  const [value, setValue] = useState('' || defaultValue)
  const [initialValue, setInitialValue] = useState<boolean>(!value)
  return (
    <div
      className={cn(
        styles.select,
        className,
        showError ? styles.isError : '',
        konto && [styles.konto]
      )}
    >
      <div className={styles.container}>
        {label && (
          <label>
            {label} {isRequired && '*'}
          </label>
        )}
        <div
          className={cn(
            styles.selectWrapper,
            initialValue ? styles.isGrey : ''
          )}
        >
          <Field
            onInvalid={() => setShowError(true)}
            as="select"
            id={id}
            name={id}
            required={isRequired}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
              setShowError(e.target.value === '' && isRequired)
              setValue(e.target.value)
              setInitialValue(e.target.value === '')
              setFieldValue(`${id}${extensionFieldId}`, e.target.value)
            }}
            value={value}
          >
            <option value="" disabled={isRequired} selected></option>

            {!!options?.length &&
              options.map((option, key) => {
                const {text, value} = option

                return (
                  <option key={key} value={value}>
                    {text}
                  </option>
                )
              })}
          </Field>
          <Icon icon="expandMore" size={24} sizeViewbox={24} />
        </div>
      </div>
      {description && <p className={styles.description}>{description}</p>}
      {showError && <InputError name={errorMessage} />}
    </div>
  )
}
