import cn from 'classnames'
import styles from './Label.module.scss'
type labelProps = {
  text: string
  divider?: boolean
}
export default function Label({text, divider}: labelProps) {
  return (
    <div className={cn(styles.label, {[styles.divider]: divider})}>{text}</div>
  )
}
