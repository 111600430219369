import cn from 'classnames'

import styles from './SubtitleWithDot.module.scss'

type SubtitleWithDotProps = {
  word1?: string
  word2?: string
  className: string
}
export default function SubtitleWithDot({
  word1,
  word2,
  className
}: SubtitleWithDotProps) {
  const formattedHTML = `${word1}${word1 && word2 ? '<i></i>' : ''}${word2}`

  return (
    <div
      className={cn(className ? className : '', styles.subtitleWithDot)}
      dangerouslySetInnerHTML={{__html: formattedHTML}}
    />
  )
}
